import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import csCS from "./cs-CS.json"
import deDE from "./de-DE.json"
import enUS from "./en-US.json"
import esES from "./es-ES.json"
import frFR from "./fr-FR.json"
import huHU from "./hu-HU.json"
import itIT from "./it-IT.json"
import jaJA from "./ja-JA.json"
import koKO from "./ko-KO.json"
import nlNL from "./nl-NL.json"
import plPL from "./pl-PL.json"
import ptPT from "./pt-PT.json"
import thTH from "./th-TH.json"
import zhHK from "./zh-HK.json"
import { LANGUAGES } from "../constants"

const resources = {
  [LANGUAGES["de-DE"]]: {
    translation: deDE,
  },
  [LANGUAGES["en-US"]]: {
    translation: enUS,
  },
  [LANGUAGES["zh-HK"]]: {
    translation: zhHK,
  },
  [LANGUAGES["hu-HU"]]: {
    translation: huHU,
  },
  [LANGUAGES["cs-CS"]]: {
    translation: csCS,
  },
  [LANGUAGES["es-ES"]]: {
    translation: esES,
  },
  [LANGUAGES["fr-FR"]]: {
    translation: frFR,
  },
  [LANGUAGES["it-IT"]]: {
    translation: itIT,
  },
  [LANGUAGES["ja-JA"]]: {
    translation: jaJA,
  },
  [LANGUAGES["ko-KO"]]: {
    translation: koKO,
  },
  [LANGUAGES["nl-NL"]]: {
    translation: nlNL,
  },
  [LANGUAGES["pl-PL"]]: {
    translation: plPL,
  },
  [LANGUAGES["pt-PT"]]: {
    translation: ptPT,
  },
  [LANGUAGES["th-TH"]]: {
    translation: thTH,
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: LANGUAGES["en-US"],
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
