import React, { useEffect } from "react"
import PropTypes from "prop-types"
import SEO from "./seo"
import IntroHeader from "./intro-header"
import MainHeader from "./main-header"
import Footer from "./footer"
import { I18nextProvider } from "react-i18next"
import i18n from "../i18n"
import api from "../utilities/api"

const Layout = ({ children, seoTitle = "1 change", style, path }) => {
  const introHeaderPages = [
    "/",
    "/welcome",
    "/choose-password",
    "/login",
    "/signup",
    "/resilience-intro",
    "/resilience-result",
    "/resilience-test",
    "/welcome/",
    "/choose-password/",
    "/login/",
    "/signup/",
    "/resilience-intro/",
    "/resilience-result/",
    "/resilience-test/",
  ]

  const displayIntroHeader = introHeaderPages.includes(path)
  const showFooter = path !== "/resilience-test" && path !== "/resilience-test/" // We want to hide the footer during the test

  useEffect(() => {
    if (
      localStorage.getItem("auth_token") ||
      sessionStorage.getItem("auth_token")
    ) {
      const token =
        localStorage.getItem("auth_token") ||
        sessionStorage.getItem("auth_token")
      api.setAuthorizationXToken(token)
    }
  }, [])

  return (
    <I18nextProvider i18n={i18n}>
      <div id="layout-wrapper" style={style}>
        <div className="container">
          <div className="layout">
            <SEO title={seoTitle} />
            {displayIntroHeader ? (
              <IntroHeader path={path} />
            ) : (
              <MainHeader path={path} />
            )}

            <main>{children}</main>
            {showFooter && <Footer />}
          </div>
        </div>
      </div>
    </I18nextProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
