import React, { useEffect, useState } from "react"
import { getUserProgress } from "../queries/user"

const PointProgress = ({ setHasPoints, isDark, size }) => {
  const [userProgressData, setUserProgressData] = useState(null)
  const {
    // isLoading: upLoading,
    isError: upIsError,
    data: upData,
    error: upError,
    // isFetching: upIsFetching,
    refetch: fetchUPData,
  } = getUserProgress({
    service: "",
    relativeUrl: "/user-progress",
    params: {},
  })

  const sizes = {
    medium: "progress__point__circle--medium",
    large: "progress__point__circle--large",
  }

  useEffect(() => {
    if (!userProgressData) {
      fetchUPData()
    }
  }, [])

  useEffect(() => {
    if (upData && upData.data && upData.data.me && upData.data.me.progress) {
      if (setHasPoints) {
        setHasPoints(true)
      }
      setUserProgressData(upData.data.me.progress.points)
    }
  }, [upData])

  useEffect(() => {
    if (upIsError) {
      console.log("Error received in userProgress: ", upError)
    }
  }, [upIsError])

  // const sendPointsToParent = () =>

  return (
    <div
      className={`progress__point__circle ${sizes[size]} ${
        isDark ? "progress__point__circle--dark" : ""
      }`}
    >
      {userProgressData}
    </div>
  )
}

export default PointProgress
