import React, { useCallback } from "react"
import { Link as GatsbyLink, navigate } from "gatsby"
import { isBrowser, clearLanguageSetting } from "../utilities/helpers"
import { useApolloClient, useQuery } from "@apollo/react-hooks"
import PointProgress from "./point-progress"
import { TEAM_ME } from "../gql/user"
import LanguageSwitcher from "./language-switcher"
import { Trans } from "react-i18next"
import { isFeatureEnabled } from "../utilities/features"
import brandLogo from "./../assets/brands/te/logo.png"
import { APP_ROUTES } from "../constants"

const Link = props => {
  return (
    isFeatureEnabled(props.to) && (
      <GatsbyLink {...props}>{props.children}</GatsbyLink>
    )
  )
}

const MainHeader = ({ path }) => {
  const client = useApolloClient()
  const { data: userData, loading: userDataLoading } = useQuery(TEAM_ME)
  const logout = () => {
    if (isBrowser()) {
      localStorage.removeItem("auth_token")
      sessionStorage.removeItem("auth_token")
      client.cache.reset()
      clearLanguageSetting()
      navigate("/login")
    }
  }
  const isTeamAccount = useCallback(() => {
    if (
      !userDataLoading &&
      userData &&
      userData.me &&
      userData.me.teamAccount
    ) {
      return userData.me.teamAccount
    }
    return false
  }, [userData, userDataLoading])

  return (
    <>
      <DesktopHeader
        path={path}
        logout={logout}
        isTeamAccount={isTeamAccount()}
        userData={userData}
      />
      <MobileHeader
        path={path}
        logout={logout}
        isTeamAccount={isTeamAccount()}
        userData={userData}
      />
    </>
  )
}

const DesktopHeader = ({ path, logout, isTeamAccount, userData }) => {
  let isNestedJourneyRoute = false

  if (path) {
    const split = path.split("/")
    isNestedJourneyRoute = split.includes("journeys")
  }
  return (
    <header className="main-header">
      <nav className="main-header__navigation">
        <div className="main-header__navigation-wrapper">
          <Link
            to={APP_ROUTES.home}
            activeClassName="main-header__nav-link--active"
            className="main-header__nav-link"
          >
            <Trans i18nKey="main-header.homebase" />
          </Link>
          <Link
            to={APP_ROUTES.intro}
            className="main-header__nav-link main-header__navigation-logo no-margin--right"
          >
            <Trans i18nKey="header.links.intro" />
          </Link>
          <Link
            to={APP_ROUTES.journeys}
            activeClassName="main-header__nav-link--active"
            partiallyActive={isNestedJourneyRoute}
            className="main-header__nav-link"
          >
            <Trans i18nKey="main-header.journeys" />
          </Link>
          {isTeamAccount && (
            <Link
              to={APP_ROUTES.team}
              activeClassName="main-header__nav-link--active"
              className="main-header__nav-link"
            >
              <Trans i18nKey="main-header.myteam" />
            </Link>
          )}
          <Link
            to={APP_ROUTES.feed}
            activeClassName="main-header__nav-link--active"
            className="main-header__nav-link"
          >
            <Trans i18nKey="main-header.feed" />
          </Link>
          <Link
            to={APP_ROUTES.leaderBoard}
            activeClassName="main-header__nav-link--active"
            className="main-header__nav-link"
          >
            <Trans i18nKey="main-header.leaderboard" />
          </Link>
          <Link
            to={APP_ROUTES.progress}
            activeClassName="main-header__nav-link--active"
            className="main-header__nav-link"
          >
            <Trans i18nKey="main-header.yourprogress" />
          </Link>
          <PointProgress size="medium" />
          <button
            className="button button--link main-header__nav-link"
            onClick={logout}
          >
            <Trans i18nKey="main-header.logout" />
          </button>
          <LanguageSwitcher userData={userData} />
          <a href={APP_ROUTES.home}>
            <img src={brandLogo} className="header_logo" alt="TE" />
          </a>
        </div>
      </nav>
    </header>
  )
}

const MobileHeader = ({ path, logout, isTeamAccount, userData }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [backgroundColor, setBackgroundColor] = React.useState("")

  React.useEffect(() => {
    const bgColor = document.querySelector("#layout-wrapper").style
      .backgroundColor
    setBackgroundColor(bgColor)
  }, [])

  let styles = {
    backgroundColor: backgroundColor,
  }

  if (isOpen) {
    styles = {
      ...styles,
      boxShadow:
        "0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06)",
    }
  }

  return (
    <>
      <header className="mobile-header" style={styles}>
        <div className="container mobile-header__wrapper">
          <div className="mobile-header__logo-wrapper"></div>
          <div className="mobile-header__active-page ">
            {path && !isOpen && (
              <Link
                className="mobile-navigation__nav-link mobile-navigation__nav-link--active "
                to={`${path}`}
                onClick={() => setIsOpen(true)}
              >
                {path.split("/")[1].toUpperCase()}
              </Link>
            )}
            {isOpen && (
              <MobileNavigation
                isTeamAccount={isTeamAccount}
                isOpen={isOpen}
                logout={logout}
                userData={userData}
              />
            )}
          </div>
          <div className="mobile-header__hamburger-wrapper">
            <button
              className="mobile-header__hamburger"
              onClick={() => setIsOpen(!isOpen)}
            >
              <svg
                width="20px"
                height="10px"
                viewBox="0 0 20 10"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  id="Mobile"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                  strokeLinecap="square"
                >
                  <g
                    id="Mobile-Homebase-Scroll-2"
                    transform="translate(-334.000000, -25.000000)"
                    stroke="#000000"
                    strokeWidth="2"
                  >
                    <g id="Group-3" transform="translate(20.000000, 20.000000)">
                      <g
                        id="Group-2"
                        transform="translate(315.000000, 5.500000)"
                      >
                        <path d="M0,0.5 L18,0.5" id="Line-2"></path>
                        <path d="M0,8.5 L18,8.5" id="Line-2-Copy"></path>
                        <path d="M0,4.5 L18,4.5" id="Line-2-Copy-2"></path>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </button>
          </div>
        </div>
      </header>
    </>
  )
}

const MobileNavigation = ({ logout, isTeamAccount, userData }) => {
  return (
    <nav className="mobile-navigation">
      <Link
        to={APP_ROUTES.home}
        activeClassName="mobile-navigation__nav-link--active"
        className="mobile-navigation__nav-link"
        style={{ marginTop: 0 }}
      >
        <Trans i18nKey="main-header.homebase" />
      </Link>
      <Link
        to={APP_ROUTES.intro}
        activeClassName="mobile-navigation__nav-link--active"
        className="mobile-navigation__nav-link"
      >
        <Trans i18nKey="header.links.intro" />
      </Link>
      <Link
        to={APP_ROUTES.journeys}
        activeClassName="mobile-navigation__nav-link--active"
        className="mobile-navigation__nav-link"
      >
        <Trans i18nKey="main-header.journeys" />
      </Link>
      <Link
        to={APP_ROUTES.feed}
        activeClassName="mobile-navigation__nav-link--active"
        className="mobile-navigation__nav-link"
      >
        <Trans i18nKey="main-header.feed" />
      </Link>
      {isTeamAccount && (
        <Link
          to={APP_ROUTES.team}
          activeClassName="mobile-navigation__nav-link--active"
          className="mobile-navigation__nav-link"
        >
          <Trans i18nKey="main-header.myteam" />
        </Link>
      )}

      <Link
        to={APP_ROUTES.progress}
        activeClassName="mobile-navigation__nav-link--active"
        className="mobile-navigation__nav-link"
      >
        <Trans i18nKey="main-header.yourprogress" />
      </Link>
      <Link
        to={APP_ROUTES.leaderBoard}
        activeClassName="mobile-navigation__nav-link--active"
        className="mobile-navigation__nav-link"
      >
        <Trans i18nKey="main-header.leaderboard" />
      </Link>
      <button
        className="button button--link mobile-navigation__nav-link"
        onClick={logout}
      >
        <Trans i18nKey="main-header.logout" />
      </button>
      <LanguageSwitcher userData={userData} />
    </nav>
  )
}

export default MainHeader
