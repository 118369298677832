import { APP_ROUTES } from "../constants"

export const features = [
  {
    name: "Home",
    route: APP_ROUTES.home,
    isEnabled: true,
  },
  {
    name: "New Home Base",
    route: "/homebase-new",
    isEnabled: true,
  },
  {
    name: "Journeys",
    route: APP_ROUTES.journeys,
    isEnabled: true,
  },
  {
    name: "More Journeys",
    route: APP_ROUTES.journeys,
    isEnabled: false,
  },
  {
    name: "Feed",
    route: "/feed",
    isEnabled: true,
  },
  {
    name: "My Team",
    route: "/team",
    isEnabled: true,
  },
  {
    name: "Leaderboard",
    route: APP_ROUTES.leaderBoard,
    isEnabled: true,
  },
  {
    name: "1 Change",
    route: APP_ROUTES.intro,
    isEnabled: true,
  },
]

export const isFeatureEnabled = nameOrRoute => {
  const feature = features.find(
    existingFeature =>
      existingFeature.name === nameOrRoute ||
      existingFeature.route === nameOrRoute
  )
  return feature ? feature.isEnabled : true
}
